@mixin resetButton {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;

  &:focus,
  &:active {
    outline: none;
  }
}

@mixin make-container() {
  //padding-right: ($grid-gutter-width / 2);
  //padding-left: ($grid-gutter-width / 2);
  margin-right: auto;
  margin-left: auto;
}

@mixin media-up($breakpoint) {
  $breakpoint-value: map-get($breakpoints, $breakpoint);

  @media (min-width: $breakpoint-value) {
    @content;
  }
}

@mixin media-down($breakpoint) {
  $breakpoint-value: map-get($breakpoints, $breakpoint);

  @media screen and (max-width: ($breakpoint-value - 1)) {
    @content;
  }
}

@mixin button {
  width: 310px;
  display: block;
  margin: 0 auto;
  color: #FFFFFF;

  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  transition: 300ms ease-out;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
  position: relative;

  &:hover {
    @include media-up(md) {
      cursor: pointer;
    }
  }
}

@mixin bgLinesCircle {
  position: absolute;
  width: 520px;
  height: 520px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-image: url('../../images/lot-bg-lines.png');
  background-repeat: no-repeat;
  background-position: center center;
  animation: orbit 15000ms linear infinite;
  transform-origin: center center;
}

@keyframes orbit {
  from {
    transform: translateX(-50%) rotate(0deg);
  }
  to {
    transform: translateX(-50%) rotate(360deg);
  }
}