
.slot {
  width: 270px;
  height: 160px;
  background: #333;
  overflow: hidden;
  position: relative;

  &-container {
    padding-top: 140px;
    padding-bottom: 120px;
    display: flex;
    align-items: center;
    justify-content: center;

    // bugged image. waiting for workable version
    //background: url('../../images/slot-shadow.svg');
    //background-repeat: no-repeat;
    //background-position: center top 275px;
  }

  &:before, &:after {
    content: '';
    position: absolute;

    left: 0;
    width: 100%;
    height: 50px;
    display: block;
    z-index: 2;
  }

  &:before {
    top: 0;
    background-image: linear-gradient(to bottom, black, transparent);
  }

  &:after {
    bottom: 0;
    background-image: linear-gradient(to top, black, transparent);
  }

  &__frame {
      display: flex;
      justify-content: space-around;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
  }

  &__bar {
    width: 80px;
    box-sizing: border-box;
    background: #ccc;
    background-image: url('../../images/sprite.png');
    background-size: cover;
    transition: all 300ms;

    &:nth-child(1) {
      background-position-y: 1025%;
      transition: all 1500ms ease-out;
    }

    &:nth-child(2) {
      background-position-y: 1075%;
      transition: all 2300ms ease-out;
    }

    &:nth-child(3) {
      background-position-y: 1175%;
      transition: all 1800ms ease-out;
    }
    .result-1 & {
      background-position-y: 75%;
    }

    .result-2 & {
      background-position-y: 125%;
    }
  }
}