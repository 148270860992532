.section05 {
  padding: 17px 32px 135px;
  overflow: hidden;

  background: url('../../images/bg-win.svg');
  background-repeat: no-repeat;
  background-position: center top 10px;
  text-align: center;

  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 120%;
    color: #00A354;
    text-align: inherit;
  }

  &__sum {
    font-family: Impact;
    font-style: normal;
    font-weight: normal;
    font-size: 39px;
    line-height: 120%;
    color: #F2994A;

    &--extra {

    }
  }

  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: #424242;
    max-width: 220px;
    margin: 0 auto;

    &--top {
      margin-bottom: 25px;

      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
        width: 27px;
        height: 25px;
        background: url('../../images/cross.svg') no-repeat;
      }
    }
  }

  &__appeal {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 120%;
    color: #A2A2A2;
    max-width: 220px;
    margin: 0 auto;
    text-align: center;
  }
  &__image {
    width: 120px;
  }
}