*,
*::before,
*::after {
  box-sizing: border-box;
}

html, body, #root, #root>div {
  height: 100%
}

html {
  min-width: 320px;
  font-size: 18px;
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  @include media-down(md) {
    font-size: 16px;
  }

  @include media-down(xs) {
    font-size: 14px;
  }
}

body {
  width: 100%;
  margin: 0;
  font-family: $rubik;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: $black;
  text-align: left;
  background-color: #15CA72;
  overflow-x: hidden;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.no-scroll {
  &--desktop {
    overflow: hidden;

    @include media-down(md) {
      touch-action: none;
    }
  }

  @include media-down(md) {
    touch-action: none;
    overflow: hidden !important;
  }
}

.react-dropdown-select-input {
  margin-left: 0 !important;
}

main {
  overflow: hidden;
}
