.section01 {
  display: flex;
  align-content: stretch;
  justify-content: space-evenly;
  flex-direction: column;
  padding-left: 32px;
  padding-right: 32px;
  background: url('../../images/bg__decoration.svg') no-repeat;
  background-position: right -250px top 0;

  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    color: #FFFFFF;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__tomato-item {
    display: block;
    margin: 0 auto;
  }
}
