
.section04 {
  padding: 20px 32px 135px 32px;
  background-image: url('../../images/bg-bubbles.svg'),
  url('../../images/lot-bg-circle.svg');
  background-repeat: no-repeat, no-repeat;
  background-position: center top 50px, center top 20px;
  position: relative;

  &__bg-lines {
    @include bgLinesCircle;
  }
}

