

.logo {
  position: relative;
  z-index: 2;

  &__image {
    width: 248px;
    display: block;
    margin: 0 auto 15px;
    transition: 300ms ease-out;
  }

  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 32px;
    color: #fff;
    text-align: center;
    transition: all 300ms ease-out;
  }
}
