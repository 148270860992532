/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css?family=Rubik:400,500,700,900&display=swap");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block; }

[tabindex='-1']:focus {
  outline: none !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0; }

p {
  margin-top: 0;
  margin-bottom: 0; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 0; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: 0.5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

a {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

a:hover {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]) {
  text-decoration: none; }

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  max-width: 100%;
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0; }

button {
  border-radius: 0; }

button:focus {
  outline: none; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0; }

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none !important; }

@-ms-viewport {
  width: device-width; }

@keyframes orbit {
  from {
    transform: translateX(-50%) rotate(0deg); }
  to {
    transform: translateX(-50%) rotate(360deg); } }

*,
*::before,
*::after {
  box-sizing: border-box; }

html, body, #root, #root > div {
  height: 100%; }

html {
  min-width: 320px;
  font-size: 18px;
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  @media screen and (max-width: 991px) {
    html {
      font-size: 16px; } }
  @media screen and (max-width: 575px) {
    html {
      font-size: 14px; } }

body {
  width: 100%;
  margin: 0;
  font-family: "Rubik", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #000;
  text-align: left;
  background-color: #15CA72;
  overflow-x: hidden; }

.container {
  width: 100%;
  margin: 0 auto; }

.no-scroll--desktop {
  overflow: hidden; }
  @media screen and (max-width: 991px) {
    .no-scroll--desktop {
      touch-action: none; } }

@media screen and (max-width: 991px) {
  .no-scroll {
    touch-action: none;
    overflow: hidden !important; } }

.react-dropdown-select-input {
  margin-left: 0 !important; }

main {
  overflow: hidden; }

.section01 {
  display: flex;
  align-content: stretch;
  justify-content: space-evenly;
  flex-direction: column;
  padding-left: 32px;
  padding-right: 32px;
  background: url("../../images/bg__decoration.svg") no-repeat;
  background-position: right -250px top 0; }
  .section01__text {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    color: #FFFFFF;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .section01__tomato-item {
    display: block;
    margin: 0 auto; }

.section02 {
  padding: 32px 16px 47px;
  display: flex;
  align-content: stretch;
  justify-content: space-evenly;
  flex-direction: column; }
  .section02__title {
    display: flex;
    justify-content: center; }
  .section02__title-text {
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    position: relative; }
    .section02__title-text::before, .section02__title-text::after {
      content: '';
      position: absolute;
      bottom: -1px;
      width: 20px;
      height: 24px; }
    .section02__title-text::before {
      left: -25px;
      background: url("../../images/money-bag.png") no-repeat; }
    .section02__title-text::after {
      right: -25px;
      background: url("../../images/money-bag.png") no-repeat; }
  .section02__form {
    position: relative;
    width: 345px;
    padding: 19px 16px 24px;
    margin: 0 auto;
    background: #FFFFFF;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
    border-radius: 4px; }
  .section02__item {
    margin-bottom: 11px;
    position: relative; }
    .section02__item:nth-child(4) {
      margin-bottom: 18px; }
    .section02__item::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: #2ECC71;
      height: 1px; }
  .section02__input {
    width: 100%;
    color: #1A2024;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    border: none;
    outline: none;
    padding: 8px 0; }
    .section02__input--error {
      border-bottom-color: #f00; }

.form-label {
  color: #B9BFC7;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px; }

.form-error {
  position: absolute;
  left: 0;
  bottom: 0;
  color: #f00;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px; }

.section03 {
  padding: 20px 32px 135px 32px;
  background-image: url("../../images/lot-bg-circle.svg");
  background-position: center top 20px;
  background-repeat: no-repeat;
  position: relative; }
  .section03__bg-lines {
    position: absolute;
    width: 520px;
    height: 520px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-image: url("../../images/lot-bg-lines.png");
    background-repeat: no-repeat;
    background-position: center center;
    animation: orbit 15000ms linear infinite;
    transform-origin: center center; }

.section04 {
  padding: 20px 32px 135px 32px;
  background-image: url("../../images/bg-bubbles.svg"), url("../../images/lot-bg-circle.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: center top 50px, center top 20px;
  position: relative; }
  .section04__bg-lines {
    position: absolute;
    width: 520px;
    height: 520px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-image: url("../../images/lot-bg-lines.png");
    background-repeat: no-repeat;
    background-position: center center;
    animation: orbit 15000ms linear infinite;
    transform-origin: center center; }

.section05 {
  padding: 17px 32px 135px;
  overflow: hidden;
  background: url("../../images/bg-win.svg");
  background-repeat: no-repeat;
  background-position: center top 10px;
  text-align: center; }
  .section05__title {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 120%;
    color: #00A354;
    text-align: inherit; }
  .section05__sum {
    font-family: Impact;
    font-style: normal;
    font-weight: normal;
    font-size: 39px;
    line-height: 120%;
    color: #F2994A; }
  .section05__label {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: #424242;
    max-width: 220px;
    margin: 0 auto; }
    .section05__label--top {
      margin-bottom: 25px;
      position: relative; }
      .section05__label--top::before {
        content: '';
        position: absolute;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
        width: 27px;
        height: 25px;
        background: url("../../images/cross.svg") no-repeat; }
  .section05__appeal {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 120%;
    color: #A2A2A2;
    max-width: 220px;
    margin: 0 auto;
    text-align: center; }
  .section05__image {
    width: 120px; }

.slot {
  width: 270px;
  height: 160px;
  background: #333;
  overflow: hidden;
  position: relative; }
  .slot-container {
    padding-top: 140px;
    padding-bottom: 120px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .slot:before, .slot:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 50px;
    display: block;
    z-index: 2; }
  .slot:before {
    top: 0;
    background-image: linear-gradient(to bottom, black, transparent); }
  .slot:after {
    bottom: 0;
    background-image: linear-gradient(to top, black, transparent); }
  .slot__frame {
    display: flex;
    justify-content: space-around;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }
  .slot__bar {
    width: 80px;
    box-sizing: border-box;
    background: #ccc;
    background-image: url("../../images/sprite.png");
    background-size: cover;
    transition: all 300ms; }
    .slot__bar:nth-child(1) {
      background-position-y: 1025%;
      transition: all 1500ms ease-out; }
    .slot__bar:nth-child(2) {
      background-position-y: 1075%;
      transition: all 2300ms ease-out; }
    .slot__bar:nth-child(3) {
      background-position-y: 1175%;
      transition: all 1800ms ease-out; }
    .result-1 .slot__bar {
      background-position-y: 75%; }
    .result-2 .slot__bar {
      background-position-y: 125%; }

.container {
  position: relative;
  height: 90vh;
  overflow-x: hidden;
  display: grid;
  grid-template-rows: minmax(min-content, max-content); }
  .container--section01 {
    padding-top: 56px; }
  .container--section02 {
    padding-top: 56px; }
    .container--section02 .falling-coin--big, .container--section02 .falling-coin--small {
      position: absolute;
      transition: 300ms ease-out; }
    .container--section02 .falling-coin--big {
      z-index: 1;
      top: 20%;
      left: -10px; }
    .container--section02 .falling-coin--small {
      right: -8px;
      bottom: 130px; }
  .container--section03 {
    padding-top: 24px; }
  .container--section04 {
    padding-top: 24px; }
  .container--section05 {
    padding-top: 24px; }
  .container--section06 {
    padding-top: 24px; }
  .container--section03 .falling-coin, .container--section04 .falling-coin, .container--section05 .falling-coin, .container--section06 .falling-coin {
    display: none; }
  .container--section03 .logo__image, .container--section04 .logo__image, .container--section05 .logo__image, .container--section06 .logo__image {
    width: 135px; }
  .container--section03 .logo__label, .container--section04 .logo__label, .container--section05 .logo__label, .container--section06 .logo__label {
    max-width: 280px;
    margin: 0 auto;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px; }

.button {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  width: 310px;
  display: block;
  margin: 0 auto;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  transition: 300ms ease-out;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
  position: relative; }
  .button:focus, .button:active {
    outline: none; }
  @media (min-width: 992px) {
    .button:hover {
      cursor: pointer; } }
  .button--orange {
    background-color: #F2C94C;
    font-size: 32px;
    border-radius: 8px;
    height: 64px; }
  .button--green {
    background: #2ECC71;
    border-radius: 4px;
    font-size: 16px;
    height: 48px; }

.falling-coin--big {
  background: url("../images/falling-coin--big.svg");
  position: absolute;
  width: 84px;
  height: 123px;
  top: 220px;
  left: -25px; }

.falling-coin--small {
  background: url("../images/falling-coin--small.png");
  width: 61px;
  height: 103px;
  position: absolute;
  bottom: 130px;
  right: -20px; }
