// colors
$black: #000;

// fonts
$rubik: 'Rubik', sans-serif;

//grid
$grid-columns: 12 !default;
$grid-gutter-width: 32px !default;

$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1920px
);
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}
