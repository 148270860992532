.section02 {

  padding: 32px 16px 47px;
  display: flex;
  align-content: stretch;
  justify-content: space-evenly;
  flex-direction: column;

  &__title {
    display: flex;
    justify-content: center;
  }

  &__title-text {
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      bottom: -1px;
      width: 20px;
      height: 24px;
    }
    &::before {
      left: -25px;
      background: url("../../images/money-bag.png") no-repeat;
    }
    &::after {
      right: -25px;
      background: url("../../images/money-bag.png") no-repeat;
    }
  }

  &__form {
    position: relative;
    width: 345px;

    padding: 19px 16px 24px;

    margin: 0 auto;

    background: #FFFFFF;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }

  &__item {
    margin-bottom: 11px;
    position: relative;

    &:nth-child(4) {
      margin-bottom: 18px;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: #2ECC71;
      height: 1px;
    }
  }

  &__input {
    width: 100%;
    color: #1A2024;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    border: none;
    outline: none;
    padding: 8px 0;
    
    &--error {
      border-bottom-color: #f00;
    }
  }
}

.form-label {
  color: #B9BFC7;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.form-error {
  position: absolute;
  left: 0;
  bottom: 0;
  color: #f00;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}